.DateRangePicker {
  font-family: 'tv2sans-regular';
  font-weight: normal;
}

.DateRangePicker * {
  border-radius: 0;
}

.DateRangePicker_picker {
  z-index: 1000;
  /* border-radius: 2rem; */
  top: 70px !important;
}

.DateRangePickerInput__withBorder {
  /* border-radius: 2rem; */
  height: 2.5rem;
  display: flex;
  align-items: center;
  border: none;
  /* border-bottom: 3px solid #3abff7; */
}

.DateInput_fang_1 {
  z-index: 1001;
  left: 38px;
}

.DateInput_1 {
  padding: 0.4rem 0.2rem;
  width: 5rem;
  /* border-radius: 2rem; */
  background: none;
}

.DateInput_1 input {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.4rem 0 0;
  text-align: center;
  font-family: 'tv2sans-regular';
  font-weight: normal;
  color: rgb(35, 31, 32) !important;
}

.DateRangePickerInput_arrow {
  font-size: 1.2rem;
  font-weight: 600;
}

input.DateInput_input__focused {
  background-color: #f7d0c2;
  border-color: white;
  transition: background-color 0.4s, border-color 0.4s;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #6f03ff;
  border-color: #b2aeff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: #fef3a3;
  border-color: #fef3a3;
  color: black;
}

.CalendarDay__selected_span {
  background-color: #b2aeff;
  border-color: #f7d0c2;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: #f7d0c2;
  border-color: #eb94a2;
  color: #6f03ff;
}

.DayPicker__withBorder,
.DayPicker_transitionContainer {
  /* border-radius: 2rem; */
}

.DateRangePicker_picker__fullScreenPortal {
  top: 0 !important;
}
