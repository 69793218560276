@import '~react-vis/dist/style.css';

@font-face {
  font-family: 'tv2sans-regular';
  src: url('./media/fonts/tv2sans/regular.woff2') format('woff2'),
    url('./media/fonts/tv2sans/regular.woff') format('woff'),
    url('./media/fonts/tv2sans/regular.ttf') format('truetype'), url('./media/fonts/tv2sans/bold.woff2') format('woff2');
}

@font-face {
  font-family: 'tv2sans-bold';
  src: url('./media/fonts/tv2sans/bold.woff2') format('woff2'), url('./media/fonts/tv2sans/bold.woff') format('woff'),
    url('./media/fonts/tv2sans/bold.ttf') format('truetype');
}

@font-face {
  font-family: 'tv2sans-light';
  src: url('./media/fonts/tv2sans/light.woff2') format('woff2'), url('./media/fonts/tv2sans/light.woff') format('woff'),
    url('./media/fonts/tv2sans/light.ttf') format('truetype');
}

@font-face {
  font-family: 'tv2sans-medium';
  src: url('./media/fonts/tv2sans/medium.woff2') format('woff2'),
    url('./media/fonts/tv2sans/medium.woff') format('woff'), url('./media/fonts/tv2sans/medium.ttf') format('truetype');
}

@font-face {
  font-family: 'tv2sans-condensed-bold';
  src: url('./media/fonts/tv2sans-condensed/bold.woff2') format('woff2'),
    url('./media/fonts/tv2sans-condensed/bold.woff') format('woff'),
    url('./media/fonts/tv2sans-condensed/bold.ttf') format('truetype');
}

@font-face {
  font-family: 'tv2sans-condensed-light';
  src: url('./media/fonts/tv2sans-condensed/light.woff2') format('woff2'),
    url('./media/fonts/tv2sans-condensed/light.woff') format('woff'),
    url('./media/fonts/tv2sans-condensed/light.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'tv2sans-regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.autocompleteInput:focus {
  outline: 0;
  cursor: text !important;
}

.VictoryContainer svg {
  height: 100% !important;
}

.rdt_Table {
  font-family: 'tv2sans-regular';
}

.Dropdown-control {
  border: 2px solid #474747 !important;
}
/*
@media(max-width: 768px) {
  .rdt_Table {
    width: calc(100% - 4px) !important;
  }

  .rdt_TableFooter {
    width: calc(100% - 20px) !important;
  }
  .rdt_TableFooter span {
    display: none;
  }
} */
